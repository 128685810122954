.sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%;
    background-color: #212121;
    transition: all 0.3s ease;
    z-index: 9999;

    ul.menu {
        padding: 0;

        li {
            list-style: none;
            text-align: center;

            @media screen and (max-width: 480px) {
                text-align: left;
            }

            &.current-menu-item {
                a {
                    color: rgb(249, 185, 107);
                    font-weight: 700;
                }
            }

            a {
                font-size: calc(17px + (30 - 17) * ((100vw - 300px) / (1920 - 300)));
                font-family: $secondary-font;
                text-transform: uppercase;
                color: #ffffff;

                &:hover {
                    text-decoration: underline;
                    color: $secondary;
                }

            }
        }
    }


    .sidebar-content {
        padding: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .close-btn {
            color: $secondary;
            text-decoration: none;
            position: absolute;
            top: 5%;
            right: 5%;
            width: 25px;
            height: 25px;
            transform: rotate(45deg);

            span {
                position: absolute;
                display: block;
                width: 21px;
                height: 2px;
                background: rgb(248, 182, 77);
                right: 13px;
                top: 10px;
                left: 1px;
                -webkit-transition: height 0.150s ease-out;
                -moz-transition: height 0.150s ease-out;
                -ms-transition: height 0.150s ease-out;
                -o-transition: height 0.150s ease-out;
                transition: height 0.150s ease-out;

                &.vr {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    transform: rotate(90deg);
                    width: 21px !important;
                    height: 2px !important;
                    min-height: 1px !important;
                    opacity: 1 !important;
                }
            }

            &:hover{
                span{
                    height: 4px !important;
                }
            }
        }

        .sidebar_logo {
            max-width: 170px;

            img {
                width: 100%;
            }

            @media screen and (max-width: 960px) {
                display: none;
            }
        }
    }
}

.sidebar-open {
    right: 0;
}

.mainContainer {
    position: relative;
    transition: all 0.3s ease-in-out;
    right: 0;
}

.sidebar-active .mainContainer {
    right: 50%;
}

.sidebar-active .barIcon {
    opacity: 0;
}

.sidebar_footer {
    position: absolute;
    bottom: 10%;
    width: 90%;

    @media screen and (max-width: 960px) {
        justify-content: center !important;

    }

    .socialIcons {
        display: flex;
        padding: 0;
        margin-bottom: 0;

        li {
            list-style: none;
            // margin-right: 10px;

            a {
                color: #fff;
                transition: all 0.2s ease-out;
                height: 36px;
                width: 28px;
                display: flex;
                align-items: end;


                &:hover {
                    i {
                        color: rgb(249, 185, 107);
                        border-bottom: 1px solid $secondary;
                    }
                }

                i {
                    transition: all 0.2s ease-out;
                    padding-bottom: 4px;
                    border-bottom: 1px solid transparent;
                }

                .fa-twitter:hover {
                    color: #00acee;
                }

                .fa-facebook-f:hover {
                    color: #3b5998;
                }

                .fa-linkedin-in:hover {
                    color: #007FB1;
                }

                .fa-instagram:hover {
                    color: #3F729B;
                }
            }
        }
    }
}