.heroSlider {
    position: relative;
    transform: translate3d(0px, 0px, 0px);
    padding-top: 100px;


    .slick-dots {
        position: absolute;
        right: 2%;
        top: 50%;
        padding: 0;

        @media screen and (max-width: 825px) {
            display: none !important;
        }

        li.slick-active {
            button {
                background: #f19021;
            }
        }

        li {
            list-style: none;
            margin-bottom: 10px;

            button {
                color: transparent;
                width: 4px;
                height: 4px;
                padding: 0;
                border: 0;
                border-radius: 50%;
            }
        }
    }

    .hero_slides {
        height: calc(100vh - 150px);

        background-repeat: no-repeat;
        background-size: cover;
        border: 0;
        // padding: 30px;
        // padding-right: 60px;
        // padding-left: 30px;

        // aspect-ratio: 16/8;
        @media screen and (min-width: 1281px) {
            min-height: 580px;
        }

        @media screen and (max-width: 1024px) {
            height: auto;
            aspect-ratio: 4 / 2;
        }

        @media screen and (max-width: 991px) {
            height: 380px;
            aspect-ratio: unset;
        }

        @media screen and (max-width: 480px) {
            height: 165px;
            padding-top: 0;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            object-fit: cover;
            left: 0;
            top: 0;
        }

        .slideContent {
            border: 1px solid #fff;
            height: 94%;
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding-bottom: 40px;
            // position: absolute;
            // bottom: 30px;
            // left: 30px;
            height: calc(100% - 60px);
            width: calc(100% - 90px);
            margin: 30px;
            // margin-top: 30px;


            // @media screen and (max-width: 825px) {
            //     padding-bottom: 30px;
            //     height: calc(100% - 30px) !important;
            // }

            @media screen and (max-width: 480px) {
                justify-content: center;
                border: none;
                height: 100% !important;
                padding-bottom: 0 !important;
                width: 100%;
                margin: 0;
            }

            h2 {
                // font-size: calc(22px + (35 - 22) * ((100vw - 300px) / (1920 - 300)));
                font-size: 35px;
                line-height: 1.4;
                font-weight: 600;
                font-family: $secondary-font;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 50px;

                @media screen and (max-width: 825px) {
                    font-size: 22px;
                    margin-bottom: 30px;
                }

                @media screen and (max-width: 480px) {
                    margin-bottom: 00;
                }
            }

            a {
                padding: 10px 15px;
                background: #f19021;
                // font-family: $secondary-font;
                font-size: 16px;
                text-transform: uppercase;
                color: #ffffff !important;
                text-decoration: none;

                @media screen and (max-width: 480px) {
                    display: none;
                }
            }
        }
    }
}