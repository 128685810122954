@font-face {
    font-family: 'Adobe Garamond Pro';
    src: url('../fonts/AGaramondPro-Bold.eot');
    src: url('../fonts/AGaramondPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AGaramondPro-Bold.woff2') format('woff2'),
        url('../fonts/AGaramondPro-Bold.woff') format('woff'),
        url('../fonts/AGaramondPro-Bold.ttf') format('truetype'),
        url('../fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src: url('../fonts/AGaramondPro-BoldItalic.eot');
    src: url('../fonts/AGaramondPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AGaramondPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/AGaramondPro-BoldItalic.woff') format('woff'),
        url('../fonts/AGaramondPro-BoldItalic.ttf') format('truetype'),
        url('../fonts/AGaramondPro-BoldItalic.svg#AGaramondPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src: url('../fonts/AGaramondPro-Italic.eot');
    src: url('../fonts/AGaramondPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AGaramondPro-Italic.woff2') format('woff2'),
        url('../fonts/AGaramondPro-Italic.woff') format('woff'),
        url('../fonts/AGaramondPro-Italic.ttf') format('truetype'),
        url('../fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src: url('../fonts/AGaramondPro-Regular.eot');
    src: url('../fonts/AGaramondPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AGaramondPro-Regular.woff2') format('woff2'),
        url('../fonts/AGaramondPro-Regular.woff') format('woff'),
        url('../fonts/AGaramondPro-Regular.ttf') format('truetype'),
        url('../fonts/AGaramondPro-Regular.svg#AGaramondPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Light.eot');
    src: url('../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Light.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Light.woff') format('woff'),
        url('../fonts/HelveticaNeue-Light.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Medium.eot');
    src: url('../fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Medium.woff') format('woff'),
        url('../fonts/HelveticaNeue-Medium.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Roman.eot');
    src: url('../fonts/HelveticaNeue-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Roman.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Roman.woff') format('woff'),
        url('../fonts/HelveticaNeue-Roman.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Roman.svg#HelveticaNeue-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


