footer {
    padding-top: 55px;
    // padding-top: 115px;
    padding-bottom: 15px;

    .links {
        padding: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
        // margin-bottom: 75px;

        @media screen and (max-width: 991px) {
            display: none;
        }

        li {
            list-style: none;
            margin: 0 15px;

            &:nth-child(1) a:after {
                background: #00aced;
            }

            &:nth-child(2) a:after {
                background: #3b5998;
            }

            &:nth-child(3) a:after {
                background: #3399CC;
            }

            &:nth-child(4) a:after {
                background: #3F729B;
            }



            a {
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                font-family: $secondary-font;
                color: #959595;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background: #000;
                    transition: all 0.2s ease-in-out;
                    opacity: 0;
                }

                &:hover::after {
                    bottom: -5px;
                    opacity: 1;
                }
            }
        }
    }

    .footer-info {
        display: flex;    
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        font-size: 13px;
        text-align: center;
        color: #828282;

        span {
            white-space: nowrap;
        }

        a {
            color: #828282;
        }

        p {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 3px;
        }
    }

    .logo {
        text-align: center;
        margin: 48px 0;
        padding-bottom: 5px;

        img {
            max-width: 200px;
        }
    }
}


