//font size

$px64: 4rem;
$px62: 3.875rem;
$px60: 3.75rem;
$px58: 3.625rem;
$px56: 3.5rem;
$px55: 3.438rem;
$px54: 3.375rem;
$px52: 3.25rem;
$px50: 3.125rem;
$px48: 3rem;
$px46: 2.875rem;
$px45: 2.8125rem;
$px44: 2.75rem;
$px42: 2.625rem;
$px40: 2.5rem;
$px38: 2.375rem;
$px36: 2.25rem;
$px35: 2.1875rem;
$px34: 2.125rem;
$px32: 2rem;
$px30: 1.875rem;
$px29: 1.813rem;
$px28: 1.75rem;
$px26: 1.625rem;
$px25: 1.563rem;
$px24: 1.5rem;
$px22: 1.375rem;
$px21: 1.313rem;
$px20: 1.25rem;
$px19: 1.1875rem;
$px18: 1.125rem;
$px17: 1.0625rem;
$px16: 1rem;
$px15: 0.9375rem;
$px14: 0.875rem;
$px13: 0.8125rem;
$px12: 0.75rem;


$primary:       #981b1e;
$primary-light:  lighten($primary, 30%);
$primary-dark:  darken($primary, 30%);
$secondary:     #F8B64D;
$secondary-light:  lighten($secondary, 30%);
$secondary-dark:  darken($secondary, 30%);

$body-bg:                   #fff;
$body-color:                #111111;

$grid-columns:                12 !default;
$grid-gutter-width:           30px;
$grid-row-columns:            6 !default;
$font-family-sans-serif:      "Open Sans", sans-serif;
$secondary-font:              "Montserrat", serif;
$font-family-base:            $font-family-sans-serif;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$link-decoration:             none !default;
$link-color:                  $primary !default;
$headings-font-family:        $font-family-sans-serif;
$headings-font-weight:        700;
$link-hover-decoration:       none;
$input-btn-padding-y:         8px;
$input-btn-padding-x:         20px;

$btn-font-family:             $font-family-sans-serif;
$btn-font-weight:             400;
$btn-font-size:               $px18;
$btn-border-radius:           0px;



     


