.home .theme-header{
    background: $primary;
}

.theme-header {
    background: rgba(0, 0, 0, .5);
    min-height: 100px;
    position: absolute; 
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: all 0.35s ease-out;
   

    &.sticky {
        position: fixed;
        animation: slideDown 0.35s ease-out;
        box-shadow: 0px 1px 2px #ffffffc7;
    }

    .logo {
        max-width: 170px;

        img {
            width: 100%;
            transition: all 0.3s ease; 
        }
    }

    .barIcon {
        position: absolute;
        right: 6%;
        a {
            color: #f7941d;

            i {
                font-size: 25px;
                transition: color 0.3s ease; 
            }
        }
    }
}

@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }