.container {
    padding: 0 3%;
    max-width: 100%;
}

h1.h1 {
    font-size: 35px
}

h2.h2 {
    font-size: 33px;
}

h3.h3 {
    font-size: 30px;
}

h4.h4 {
    font-size: 24px;
}

h5.h5 {
    font-size: 18px;
}

.width50 {
    width: 50%;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.width70 {
    width: 70%;
    margin: auto;

    @media screen and (max-width: 767px) {
        width: 90%;
    }
}

.width90 {
    width: 90%;
    margin: auto;
}

.padding5 {
    padding: 4%;
}

// spinner 
.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  
    .logo {
      img {
        width: 80px; 
        height: auto;
        position: relative;
        z-index: 2;
      }
    }
  
    .spinner {
        position: absolute;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 50%;
    clip-path: inset(90% 0 0 0);
    animation: rotate 0.8s linear infinite;
    }
  }
  

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  