.moreAbout {
    background: $primary;
    padding-top: 46px;
    padding-bottom: 12px;
    position: relative;

    @media screen and (max-width: 991px) {
        padding: 20% 0;

    }

    .container {
        max-width: 66%;
    }

    p {
        font-size: 15px;
        line-height: 1.6;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-bottom: 26px;
    }

    a {
        border: 1px solid white;
        max-width: 242px;
        margin: 50px auto 0 auto;
        display: block;
        text-align: center;
        font-size: 13px;
        border-radius: 0px;
        color: #ffffff;
        background-color: $primary;
        line-height: 2.3;
        padding: 3px 13px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $primary;
            background: #ffffff;
        }
    }
}

.grid {
    overflow-x: hidden;

    .row>.col-lg-3:nth-child(1) .gridBody:after {
        background: #9a1f22;
    }

    .row>.col-lg-3:nth-child(2) .gridBody:after {
        background: #206d6f;
    }

    .row>.col-lg-3:nth-child(3) .gridBody:after {
        background: #cb9c1d;
    }

    .row>.col-lg-3:nth-child(4) .gridBody:after {
        background: #3e6d2c;
    }
}

.gridBody {
    position: relative;

    @media screen and (max-width: 991px) {
        margin-bottom: 45px;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 15px;
        bottom: 0;
        // transition: all 0.3s ease-in-out;
        left: 0;
        z-index: -1;
    }

    &:nth-child(1):after {
        background: $primary;
    }

    &:nth-child(2):after {
        background: #206d6f;
    }

    &:hover::after {
        height: 100%;
    }

    &:hover .label {
        color: #ffffff;
    }

    &:hover .image .hide {
        display: none;
    }

    &:hover .image .show {
        display: block;
    }

    a {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .image {
        max-width: 211px;
        margin: auto;

        img {
            width: 100%;
        }

        .show {
            display: none;
        }
    }

    .label {
        font-family: $secondary-font;
        text-align: center;
        font-weight: 600;
        padding: 5px 0 45px 0;
        text-transform: uppercase;

    }
}

.ourPartners {
    background-color: rgb(236, 234, 235);
    padding: 5%;

    /* Hide the popup by default */
    .popup {
        display: none;
        position: fixed;
        top: 0%;
        left: 0%;
        transform: scale(0.8);
        background: #000000ad;
        z-index: 1000;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .popup.active {
        display: block;
        transform: scale(1);
        opacity: 1;
    }



    /* Popup content */
    .sliderPopup .slideBody {
        background: white;
        width: 80%;
        overflow-y: auto;
        margin: auto;
        height: 100%;

    }


    .closePopup {
        width: 16px;
        height: 16px;
        padding: 0;
        position: absolute;
        right: 20px;
        top: 20px;
    }


    .prev-btn,
    .next-btn {
        border: 0;
        outline: 0;
        background: transparent;
    }

    .next-btn {
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translate(0, -50%);

        @media screen and (max-width: 575px) {
            right: 55px;
        }
    }

    .prev-btn {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        @media screen and (max-width: 575px) {
            left: -22px;
        }
    }

    .prev-btn:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 35px;
        margin-left: 25px;
        border: medium inset transparent;
        border-right: 27px solid #3F3F3F;
        border-top-width: 21px;
        border-bottom-width: 21px;
        opacity: 0.7;
    }

    .prev-btn:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 35px;
        margin-left: 31px;
        border: medium inset transparent;
        border-right: 17px solid #FFF;
        border-top-width: 13px;
        border-bottom-width: 13px;
        top: 8px;
    }

    .next-btn:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 35px;
        margin-left: 35px;
        border: medium inset transparent;
        border-left: 27px solid #3F3F3F;
        border-top-width: 21px;
        border-bottom-width: 21px;
        opacity: 0.7;
    }

    .next-btn:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 35px;
        margin-left: 39px;
        border: medium inset transparent;
        border-left: 17px solid #FFF;
        border-top-width: 13px;
        border-bottom-width: 13px;
        top: 8px;
    }


    h2 {
        font-family: 'Adobe Garamond Pro';
        font-style: italic;
        font-weight: 300;
        text-transform: capitalize;
        border-bottom: 3px solid;
        max-width: 300px;
        text-align: center;
        margin: auto;
        font-size: 30px;
        line-height: 44px;
        border-bottom: 3px solid #000000;
        color: #000000;
    }

    .partnerCard {
        width: 100%;
        height: 100%;
        border: 1px solid #ecebeb;
        margin-top: 25px;
        padding: 15px 0;
        text-align: center;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 460px) {
            height: 260px;
        }

        &:hover {
            border: 1px solid #981b1e;

            img {
                filter: grayscale(0%)
            }

            p,
            a {
                display: inline-block;
            }
        }

        img {
            max-width: 272px;
            width: 100%;
            filter: grayscale(100%)
        }

        p {
            color: #981b1e;
            text-transform: uppercase;
            text-align: center;
            font-family: 'Montserrat' !important;
            font-weight: 400;
            margin-bottom: 12px;
            display: none;
        }

        a {
            background: #f18e1d;
            text-transform: uppercase;
            color: #FFFFFF;
            padding: 10px 30px;
            display: inline;
            text-align: center;
            font-size: 11px;
            display: none;
            transition: all 0.2s ease-out;

            &:hover {
                color: #000;
            }
        }
    }

}

.popupParent {



    .slideBody {
        max-width: 80%;
        background: #fff;
        margin: auto;
        height: 100vh;
        overflow: auto;


    }



    .modal-body {
        padding: 0;
        overflow: hidden;
    }

    .slideContent {
        padding: 80px;
        position: relative;

        @media screen and (max-width: 767px) {
            padding: 40px;
        }

        @media screen and (max-width: 600px) {
            padding: 25px;
        }

        .slideImg {
            @media screen and (max-width:991px) {
                padding-bottom: 20px;
            }

            @media screen and (min-width: 768px) {
                padding-right: 45px;
            }

            img {
                width: 100%;

                @media screen and (max-width: 600px) {
                    width: 90%;
                }
            }
        }

        h3 {
            padding-bottom: 15px;
            font-size: 24px;
            font-weight: 600;
            color: #623c3c;
            font-family: $secondary-font;
            text-transform: uppercase;
        }

        p {
            font-size: 16px;
            line-height: 1.6;
            font-weight: 300;
            color: #4f4f4f;
        }

        .service {
            color: #623c3c;
            font-size: 16px;
            border: 2px solid #ceb984;
            padding: 15px;
            line-height: 20px;
            font-family: $secondary-font;
        }
    }
}

.modal-backdrop.show {
    opacity: 0.7 !important;
}

.home {
    .bg-light {
        background: #fff;
        height: 320px !important;
        overflow: auto;

        @media screen and (max-width: 575px) {
            height: auto !important;
        }
    }

    .gallery {
        max-width: 90%;
        margin: auto;
        padding: 5%;

    }

}

.letsTalk {
    padding-left: 5%;
    background: $primary;

    #sb_instagram #sbi_images .sbi_item {
        aspect-ratio: 1;
    }

    .instagramGrid {
        padding: 0 5px;

        .sbi_feedtheme_header_text {
            //  width: 140px;
        }
    }

    #sb_instagram .sb_instagram_header .sbi_header_text,
    .sb_instagram_header .sbi_header_text {
        gap: 15px !important;
    }

    @media screen and (max-width: 991px) {
        padding-left: 0;
    }

    .content {
        @media screen and (max-width: 991px) {
            padding-left: 8%;
            padding-top: 15%;
            padding-bottom: 15%;
        }

        h3 {
            font-weight: 400;
            font-size: 32px;
            color: #ffffff;
            margin: 0;
        }

        p {
            font-size: 15px;
            line-height: 1.6;
            font-weight: 400;
            margin: 0;
            color: #ffffff;
        }

        a {
            border: 1px solid white;
            width: 130px;
            text-align: center;
            font-size: 13px;
            border-radius: 0px;
            color: rgb(255, 255, 255);
            background-color: rgb(152, 27, 30);
            display: inline-block;
            text-transform: uppercase;
            padding: 8px 13px;
            margin: 7px 0;
            transition: all 0.3s ease-in;

            &:hover {
                background: #fff;
                color: rgb(152, 27, 30);
            }
        }

    }

    .bg-light {
        background: #fff;
        height: auto;

        @media screen and (min-width:576px) and (max-width: 800px) {
            height: 320px;
            overflow: auto;
        }
    }

    .gallery {
        // max-width: 90%;
        // margin: auto;
        // padding: 5%;


        .header {
            margin-bottom: 10px;
            padding: 5px;
            padding-bottom: 0;
        }

        .icon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #333;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 36px;
                height: 36px;
            }
        }

        h5 {
            width: calc(100% - 95px);
            font-size: 20px;
            font-weight: 400;
            color: #333;
        }



        .galleryImg {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                width: 25%;
                padding: 5px;
                position: relative;

                @media screen and (max-width: 800px) {
                    width: 50%;
                }

                img {
                    width: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
                }

                .copyIcon {
                    position: absolute;
                    right: 7px;
                    top: 7px;
                    font-size: 12px;
                    width: 12px;
                }

                .playbtn {
                    width: 16px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .galleryBtn {
            display: flex;
            flex-direction: column;
            align-items: center;

            .loadBtn {
                padding: 7px 14px;
                margin: 5px auto 0 auto;
                background: #333;
                border: none;
                color: #fff;
                text-decoration: none;
                font-size: 13px;
                line-height: 1.5;
                border-radius: 4px;
                margin-top: 5px;
            }

            .followBtn {
                padding: 7px 14px;
                margin: 5px auto 0 auto;
                background: rgb(64, 139, 209);
                border: none;
                color: #fff;
                text-decoration: none;
                font-size: 13px;
                line-height: 1.5;
                border-radius: 4px;
                display: flex;
                margin-top: 5px;

                svg {
                    width: 16px;
                    margin-right: 10px;
                }
            }
        }

    }

    .action {
        text-align: center;

        @media screen and (max-width: 767px) {
            padding: 10%;
        }

        svg {
            max-width: 128px;
            margin: auto;
        }

        p {
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;
            color: #fff;

        }
    }

    .newsLetter {
        @media screen and (max-width: 991px) {
            padding: 5%;
        }

        h2 {
            font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1920 - 300)));
            color: #fff;
            font-weight: 400;
        }

        input {
            width: 100%;
            padding: 10px 15px;
            border: 1px solid #b0b6bb;
            border-radius: 4px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
            font-family: Helvetica Neue, Arial, sans-serif;
            color: #394856;
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 24px;
            outline: 0;
        }

        input[type="submit"] {
            color: #fff;
            background-color: #0078c1;
            border: 0;
            border-radius: 2px !important;

            &:hover {
                background: #3e9fda;
            }
        }

        form {
            max-width: 85%;

            @media screen and (max-width: 991px) {
                max-width: 100%;
            }
        }
    }
}

.sbi_feedtheme_header_text {
    width: calc(100% - 100px)
}

.home .instagramGrid {
    padding: 0 30px;

}

// -- about page 
.heroInnerbanner {
    // padding: 9.5% 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    background-position: center !important;
    padding-top: 28.23%;
    transform: translate3d(0px, 0px, 0px);

    @media screen and (max-width: 1024px) {
        min-height: 400px;
    }

    @media screen and (max-width: 600px) {
        min-height: 320px;
    }


    // &:before {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     background: #101010;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     opacity: 0.6;
    //     z-index: 0;
    // }

    .bannerContainer {
        width: 90%;
        margin: auto;
        border: 1px solid white;
        padding: 40px 60px;
        text-align: left;
        position: absolute;
        left: 50%;
        top: 50%;
        // top: calc(50% - 20px);
        transform: translate(-50%, -50%);


        @media screen and (max-width: 600px) {
            padding: 10px 30px;
        }

        h1 {
            font-family: $secondary-font;
            color: #fff;
            font-weight: 400;
            margin: 0;
            font-size: 33px;
            text-transform: uppercase;

            @media screen and (max-width: 600px) {
                font-size: 16px;
            }
        }
    }
}

.aboutBanner {
    // background: url("../images/aboutBanner.jpg");
}
.parallax-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; /* Ensure it spans the full height of the parent */
    z-index: -1; /* Place behind the content */

    img {
        width: 100%;
        height: 100%; /* Ensure the image covers the container */
        object-fit: cover; /* Maintain aspect ratio */
    }

    .overlay {
        position: absolute; /* Ensure it covers the entire wrapper */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(16, 16, 16, 0.6); /* Overlay color with transparency */
        z-index: 1; /* Place it above the image */
    }
}


.capabilitiesBanner {
    // background: url("../images/capabilitiesBanner.png");
}

.aboutContent {

    .leftSection {
        background: #d7d7d7;


    }

    .rightSection {

        background: $primary;



        ul {
            padding: 0;

            li {
                font-size: 18px;
                line-height: 3;
                font-weight: bold;
                list-style: none;
                color: #fff;
            }
        }

        a {
            display: block;
            // max-width: 212px;
            max-width: 50%;
            border: 1px solid #fff;
            font-size: 13px;
            text-transform: uppercase;
            line-height: 2.3;
            padding: 3px 13px;
            margin: 7px 0;
            color: #fff;
            text-align: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #f19021;
                border: 1px solid #f19021;
            }
        }
    }

    .sectionContainer {

        padding: 10% 5%;




        p {
            font-size: 15px;
            font-weight: 400;
            color: #000;
            line-height: 3;
        }
    }
}

.launchSection {
    .secContainer {
        width: 90%;
        padding: 5%;
        margin: auto;

        p {
            font-size: 15px;
            line-height: 2;
        }

        b {
            margin-bottom: 2rem;
            display: block;
        }

        a {
            max-width: 275px;
            margin: auto;
            border: 1px solid rgb(152, 27, 30);
            text-align: center;
            font-size: 13px;
            line-height: 2.3;
            padding: 3px 13px;
            display: block;
            text-transform: uppercase;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: #fff;
                background: $primary;
            }
        }
    }
}

.overview {
    @media screen and (max-width: 991px) {
        flex-direction: column !important;

        .width50 {
            width: 100%;
        }
    }

    .content {

        background: #d7d7d7;
        padding: 8% 5% 7% 5%;

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    .wrapper {


        p {
            font-size: 15px;
            font-weight: 400;
            color: #000;
            line-height: 3;
        }
    }

    .image {
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        p {
            background-color: rgba(0, 0, 0, 0.55);
            color: white;
            font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1920 - 300)));
            padding: 5px 9px;
            font-style: italic;
            position: absolute;
            bottom: 0;
            margin: 0;
            line-height: 1.2;
            right: 0;
        }
    }
}

.services {
    background-color: rgb(255, 255, 255);
    color: rgb(109, 104, 104);

    .wrapper {
        max-width: 80%;
        margin: auto;
        padding: 5%;

        .col-md-12>ul {
            display: flex;
            flex-wrap: wrap;
        }

        .col-md-12>ul>li {
            list-style: none;
            width: 50%;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        .col-md-12>ul>li ul {
            list-style: circle;
            width: 100%;
        }

        h4 {
            padding: 20px 0;
            font-weight: 400;
            margin-bottom: 0;
            line-height: 1;
            font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)))
        }

        ul {
            padding: 0;

            li {
                margin-bottom: 15px;
                list-style: circle;
                list-style-position: outside !important;
                padding-left: 20px;
                line-height: 1;
                font-family: 'Helvetica Neue';
            }
        }
    }
}

.story {
    padding-bottom: 4%;

    video {
        height: 100% !important;
        width: 100% !important;
    }

    @media screen and (max-width: 991px) {
        flex-direction: column !important;

        .width50 {
            width: 100%;
        }
    }

    h2 {
        margin-bottom: 15px;
        color: #6D6868;
        font-weight: 400;
        font-family: 'Helvetica Neue';
    }

    p {
        font-size: 15px;
        font-weight: 400;
        line-height: 2.5;
        color: #6D6868;
    }
}

.teamBanner {
    // background: url("../images/teamBanner.jpg");
    padding-top: 38.52%;
    z-index: 0 !important;

    @media screen and (min-width: 1441px) {
        p {
            padding-top: 5%;
        }
    }

    @media screen and (max-width: 1300px) {
        padding-top: 40.52%;

    }

    @media screen and (max-width: 1279px) {
        min-height: 640px;
        padding: 0;
    }

    @media screen and (max-width: 767px) {
        min-height: 750px;
    }

    @media screen and (max-width: 600px) {
        padding-bottom: 0 !important;
        min-height: 320px;
    }

    .bannerContainer {
        border: 0;
        padding: 0;
        // margin-top: 135px !important;

        // @media screen and (max-width: 600px) {
        //     margin-top: auto !important;
        // }
        h1 {
            padding: 40px 60px;
            border: 1px solid;

            @media screen and (max-width: 600px) {
                padding: 10px 30px !important
            }
        }

        p {
            // position: absolute;
            bottom: 80px;
            font-size: 16px;
            color: #fff;
            line-height: 2.8;
            letter-spacing: 1px;
            max-width: 90%;
            left: 0;
            right: 0;
            margin: auto;

            @media screen and (max-width: 600px) {
                display: none;

            }
        }
    }


}

.teamGrid {
    background: #fff;
    .wrapper {
        max-width: 80%;
        margin: auto;

        .col-md-4.col-sm-6.cardBody {
            margin-bottom: 45px;

            @media screen and (max-width: 1599px) {
                margin-bottom: 35px;
            }
        }


        .image {
            position: relative;
            aspect-ratio: 1;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        h4 {
            font-size: calc(14px + (28 - 14) * ((100vw - 300px) / (1920 - 300)));
            padding-top: 20px;
            font-weight: bold;
            color: #981b1e;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1;
        }

        p {
            padding-top: 5px;
            font-size: calc(10px + (21 - 10) * ((100vw - 300px) / (1920 - 300)));
            color: #981b1e;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1;
        }
    }
}

.contactBanner {
    // background: url("../images/contactBanner.jpg");
}

.contactForm {
    padding: 20% 0;

    input,
    textarea {
        width: 100%;
        border: 1px solid #e9e9e9;
        margin: 0;
        padding: 5px 10px;
        margin-bottom: 20px;
        outline: 0;
        color: #222;
        font-size: 15px;
        font-weight: 400;

        @media screen and (min-width: 1441px) {
            font-size: 17px;
        }

        &::placeholder {
            color: #bfc4cf;
            font-size: 15px;
            font-weight: 400;

            @media screen and (min-width: 1441px) {
                font-size: 17px;
            }
        }


        &::-webkit-input-placeholder {
            color: #bfc4cf;
            font-size: 15px;
            font-weight: 400;

            @media screen and (min-width: 1441px) {
                font-size: 17px;
            }
        }

        &:-moz-placeholder {
            color: #bfc4cf;
            font-size: 15px;
            font-weight: 400;

            @media screen and (min-width: 1441px) {
                font-size: 17px;
            }
        }

        &::-moz-placeholder {
            color: #bfc4cf;
            font-size: 15px;
            font-weight: 400;

            @media screen and (min-width: 1441px) {
                font-size: 17px;
            }
        }

        &:-ms-input-placeholder {
            color: #bfc4cf;
            font-size: 15px;
            font-weight: 400;

            @media screen and (min-width: 1441px) {
                font-size: 17px;
            }
        }
    }



    textarea {
        margin-bottom: 0;
    }

    input[type="submit"] {
        background-color: rgb(250, 198, 117);
        color: #222;
        height: 40px;
        margin-bottom: 0;
    }
}

.contactSection {
    background: #fff;
    .map {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 600px) {
    .swiper .swiper-slide .box-imgage-detail img {
        height: 100%;
    }

    .flat-slider-detail-v1 {
        margin-bottom: 0 !important;
    }

    .flat-location.flat-slider-detail-v1 {
        height: 300px;
    }

    .swiper.tf-sw-location {
        height: 100%;
    }

    .property-image {
        height: 100% !important;
    }
}

.swiper .swiper-slide .box-imgage-detail img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}


.achievementBanner {
    position: relative;
    width: 100%;
    /* padding-top: 56.25%; */
    // overflow: hidden;
    padding-top: 38%;

    @media screen and (max-width: 1024px) {
        min-height: 600px;
    }

    @media screen and (max-width: 767px) {
        min-height: 700px;
    }

    @media screen and (max-width: 600px) {
        min-height: 320px;
    }

    // @media screen and (max-width: 1920px) {
    //     height: 732px;
    // }

    // @media screen and (max-width: 1660px) {
    //     height: 632px;
    // }

    // @media screen and (max-width: 1440px) {
    //     height: 550px;
    // }

    // @media screen and (max-width: 1366px) {
    //     height: 520px;
    // }

    // @media screen and (max-width: 1280px) {
    //     height: 488px;
    // }

    // @media screen and (max-width: 991px) {
    //     height: 555px;
    // }

    // @media screen and (max-width: 600px) {
    //     min-height: 320px;
    //     height: auto;
    //     align-items: center;
    // }

    // background-image: url("../images/achievementBanner.png");
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // &:before {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     background: #101010;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     opacity: 0.6;
    //     z-index: -1;
    // }

    .bannerContainer {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        // @media screen and (min-width: 601px) and (max-width: 1280px) {
        //     margin-top: 120px !important;
        //   }
    }

    h1 {
        font-weight: 400;
        color: #fff;
        border: 1px solid white;
        padding: 40px 60px;
        text-align: left;
        font-family: $secondary-font;
        margin: 0;
        font-size: 33px;

        @media screen and (max-width: 600px) {
            padding: 10px 30px !important;
            font-size: 16px !important;
        }
    }

    p {
        line-height: 3;
        font-size: 16px;
        padding-right: 10%;
        color: #fff;
        padding-left: 8%;

        @media screen and (min-width: 1441px) {
            padding-top: 5%;
        }

        @media screen and (max-width: 600px) {
            display: none;
        }
    }
}

// team modal 
.team_Modal {
    overflow: hidden;

    iframe {
        width: 300px !important;
        max-width: 100%;
        height: 300px !important;
    }

    .modal-dialog {
        padding: 80px;
        background: #fff;
        height: 100%;
        overflow-y: auto;
        margin-top: 0 !important;

        @media screen and (max-width:600px) {
            padding: 25px;
        }
    }

    .modal-content {
        border: 0;
    }

    .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    h4 {
        font-size: 25px !important;
        font-weight: bold !important;
        line-height: 1.16 !important;
        color: #606060 !important;
        text-align: left !important;
        padding-bottom: 10px !important;
        margin: 0 !important;
        padding-top: 0 !important;
    }

    .designation {
        font-size: 22px !important;
        line-height: 1.2 !important;
        color: #606060 !important;
        padding-top: 0 !important;
        text-align: left !important;
    }

    p {
        font-size: 20px !important;
        line-height: 2.1 !important;
        color: #606060 !important;
        padding-top: 25px !important;
        text-align: left !important;
    }
}


// -- default pages css 
.def_page {
    &.launchSection {
        .secContainer a {
            max-width: auto;
            display: inline;
            border: 0;
            padding: 0;
            text-transform: none;
        }
    }

    .grid-box {
        margin: 30px 0;

        a {
            border: 1px solid rgb(152, 27, 30);
            text-align: center;
            font-size: 13px;
            line-height: 2.3;
            padding: 3px 13px;
            color: #981b1e;

            i {
                margin-left: 10px;
            }
        }
    }

    li {
        margin-bottom: 15px;
        list-style-position: outside !important;
        padding-left: 10px;
        line-height: 1;
        font-family: "Helvetica Neue";
        color: #6D6868;
    }

    ul li {
        list-style: circle;
    }

    p {
        font-size: 15px;
        font-weight: 400;
        line-height: 2.5;
        color: #6D6868;
    }

    .navigation {
        &.pagination {
            margin: 20px 0;
            justify-content: end;

            .nav-links {
                display: flex;
                align-items: center;
                gap: 15px;

                span.current {
                    background: #981b1e;
                    padding: 2px 10px;
                    color: #fff;
                }
            }
        }
    }

    .search-submit:hover {
        background-color: #981b1e;
        color: #fff;
    }

    .search-form {
        background: #d7d7d7;
        padding: 20px;
        margin-bottom: 20px;

        input {
            border: 0;
            outline: 0;
            background: #fff;
            padding: 15px;
            color: #981b1e;
            transition: all 0.2s ease-in-out;
        }
    }

}

.search-results .entry-title {
    text-align: left !important;
}

.search-results .entry-categories {
    margin-bottom: 12px;
}

.search-no-results .search-form {
    margin-top: 20px;
}

form[data-form-id="4"] .es-form-field-container .gjs-row {
    margin-bottom: 0 !important;
}

.secWrapper{
    padding-top: 4em;
    position: relative;
    z-index: 1;
    background: #fff;
}